import React from 'react'
import GalleryWithLightbox from '../../../components/gallery-with-lightbox'
import Layout from '../../../components/layout/layout'
import Header from '../../../components/header/header'
import Nav from '../../../components/nav/nav'
import Footer from '../../../components/footer/footer'

import imageThereIsAWholeWorldOutThereII from '../../../assets/images/albums/paintings/works-on-paper/there-is-a-whole-world-out-there-II.jpg'
import imageThereIsAWholeWorldOutThere from '../../../assets/images/albums/paintings/works-on-paper/there-is-a-whole-world-out-there.jpg'
import imageFlyingBlackEyeSusan from '../../../assets/images/albums/paintings/works-on-paper/flying-black-eye-susan_acrylic-on-paper-16x20in.jpg'
import image1 from '../../../assets/images/albums/paintings/works-on-paper/1-formation-deformation-s-a2.jpg'
import image2 from '../../../assets/images/albums/paintings/works-on-paper/2-formation-deformation-s2-a1.jpg'
import image3 from '../../../assets/images/albums/paintings/works-on-paper/3-formation-deformation-s-a3.jpg'
import image4 from '../../../assets/images/albums/paintings/works-on-paper/4-formation-deformation-s-v.jpg'
import image5 from '../../../assets/images/albums/paintings/works-on-paper/5-formation-deformation-s-iv.jpg'
import image6 from '../../../assets/images/albums/paintings/works-on-paper/6-formation-deformation-s-vi.jpg'
import image7 from '../../../assets/images/albums/paintings/works-on-paper/7-formation-deformation-s-x.jpg'
import image8 from '../../../assets/images/albums/paintings/works-on-paper/8-formation-deformation-s-xi.jpg'
import image9 from '../../../assets/images/albums/paintings/works-on-paper/9-formation-deformation-s-xii.jpg'
import image10 from '../../../assets/images/albums/paintings/works-on-paper/10-formation-deformation-s-ix.jpg'
import image11 from '../../../assets/images/albums/paintings/works-on-paper/11-formation-deformation-s-vii.jpg'
import image12 from '../../../assets/images/albums/paintings/works-on-paper/12-formation-deformation-s-viii.jpg'
import image13 from '../../../assets/images/albums/paintings/works-on-paper/13-formation-deformation-s-xv.jpg'
import image14 from '../../../assets/images/albums/paintings/works-on-paper/14-formation-deformation-s-ivx.jpg'
import image15 from '../../../assets/images/albums/paintings/works-on-paper/15-formation-deformation-s-xiii.jpg'
import image16 from '../../../assets/images/albums/paintings/works-on-paper/16-aerial-views-i.jpg'
import image17 from '../../../assets/images/albums/paintings/works-on-paper/17-aerial-views-ii.jpeg'
import image18 from '../../../assets/images/albums/paintings/works-on-paper/18-aerial-views-iii.jpeg'
import image19 from '../../../assets/images/albums/paintings/works-on-paper/19-aerial-views-iv.jpeg'
import image20 from '../../../assets/images/albums/paintings/works-on-paper/20-aerial-views-v.jpeg'
import image21 from '../../../assets/images/albums/paintings/works-on-paper/21-aerial-views-vi.jpeg'
import image22 from '../../../assets/images/albums/paintings/works-on-paper/22-aerial-views-vii.jpeg'
import image23 from '../../../assets/images/albums/paintings/works-on-paper/aerial-views-viii.jpg'
import image24 from '../../../assets/images/albums/paintings/works-on-paper/aerial-views-ix.jpg'
import image25 from '../../../assets/images/albums/paintings/works-on-paper/aerial-views-x.jpg'
import image26 from '../../../assets/images/albums/paintings/works-on-paper/aerial-views-xi.jpg'

import image27 from '../../../assets/images/albums/paintings/works-on-paper/aerial-views-xii.jpg'
import image28 from '../../../assets/images/albums/paintings/works-on-paper/aerial-views-xiii.jpg'
import image29 from '../../../assets/images/albums/paintings/works-on-paper/aerial-views-xiv.jpg'
import image30 from '../../../assets/images/albums/paintings/works-on-paper/aerial-views-xv.jpg'
import image31 from '../../../assets/images/albums/paintings/works-on-paper/aerial-views-xvi.jpg'


import image32 from '../../../assets/images/albums/paintings/works-on-paper/dripping_acrylic-and-oil-pen-on-canvas-paper_23.4x33in.jpeg'
import image33 from '../../../assets/images/albums/paintings/works-on-paper/formation-deformation_2017-1485lx126wcm-acrylic-paper.jpg'
import image34 from '../../../assets/images/albums/paintings/works-on-paper/antartica-nocturna.jpg'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <GalleryWithLightbox
        photos={PHOTO_SET}
        direction='column'
        margin={8}
      />
    </main>
    <Footer />
  </Layout>
)

const PHOTO_SET = [
  {
    src: imageThereIsAWholeWorldOutThereII,
    width: 960,
    height: 756,
    caption: 'There is a Whole World Out There II - acrylic and recycled chocolate foil on paper - 16"Hx20"W'
  },
  {
    src: imageThereIsAWholeWorldOutThere,
    width: 960,
    height: 752,
    caption: 'There is a Whole World Out There - acrylic and recycled chocolate foil on paper - 16"Hx20"W'
  },
  {
    src: imageFlyingBlackEyeSusan,
    width: 960,
    height: 754,
    caption: 'Flying Black-eye Susan - acrylic on paper - 16"Hx20"W'
  },
  {
    src: image1,
    width: 960,
    height: 658,
    caption: '1/15 Formation-Deformation S a2 - acrylic and oil pen on canvas paper -  11.7"x16.5"'
  },
  {
    src: image2,
    width: 960,
    height: 661,
    caption: '2/15 Formation-Deformation S2 a1 -  acrylic and oil pen on canvas paper - 11.7"x16.5"'
  },
  {
    src: image3,
    width: 960,
    height: 665,
    caption: '3/15 Formation-Deformation S a3 -  acrylic and oil pen on canvas paper - 11.7"x16.5"'
  },
  {
    src: image4,
    width: 960,
    height: 659,
    caption: '4/15 Formation-Deformation S V -  acrylic and oil pen on canvas paper - 11.7"x16.5"'
  },
  {
    src: image5,
    width: 960,
    height: 660,
    caption: '5/15 Formation-Deformation S IV -  acrylic and oil pen on canvas paper - 11.7"x16.5"'
  },
  {
    src: image6,
    width: 960,
    height: 658,
    caption: '6/15 Formation-Deformation S VI -  acrylic and oil pen on canvas paper - 11.7"x16.5"'
  },
  {
    src: image7,
    width: 960,
    height: 660,
    caption: '7/15 Formation-Deformation S X  acrylic on canvas paper - 11.7"x16.5"'
  },
  {
    src: image8,
    width: 960,
    height: 658,
    caption: '8/15 Formation-Deformation S XI -  acrylic on canvas paper - 11.7"x16.5"'
  },
  {
    src: image9,
    width: 960,
    height: 659,
    caption: '9/15 Formation-Deformation S XII -  acrylic on canvas paper - 11.7"x16.5"'
  },
  {
    src: image10,
    width: 960,
    height: 661,
    caption: '10/15 Formation-Deformation S IX - acrylic on canvas paper - 11.7"x16.5"'
  },
  {
    src: image11,
    width: 960,
    height: 659,
    caption: '11/15 Formation-Deformation S VII - acrylic on canvas paper - 11.7"x16.5"'
  },
  {
    src: image12,
    width: 960,
    height: 662,
    caption: '12/15 Formation-Deformation S VIII - acrylic on canvas paper - 11.7"x16.5"  '
  },
  {
    src: image13,
    width: 960,
    height: 658,
    caption: '13/15 Formation-Deformation S XV - acrylic on canvas paper - 11.7"x16.5"'
  },
  {
    src: image14,
    width: 960,
    height: 665,
    caption: '14/15 Formation-Deformation S XIV - acrylic on canvas paper - 11.7"x16.5"'
  },
  {
    src: image15,
    width: 960,
    height: 657,
    caption: '15/15 Formation-Deformation S XIII - acrylic on canvas paper - 11.7"x16.5"'
  },
  {
    src: image16,
    width: 960,
    height: 678,
    caption: 'Aerial Views I - acrylic and recycled candy foil on canvas paper - 16.5"x 23.4"'
  },
  {
    src: image17,
    width: 960,
    height: 674,
    caption: 'Aerial Views II - acrylic and candy foil on canvas paper - 16.5"x23.4"'
  },
  {
    src: image18,
    width: 960,
    height: 670,
    caption: 'Aerial Views III - acrylic, oil pen and candy foil on canvas paper - 16.5"x23.4" - Private Collection'
  },
  {
    src: image19,
    width: 960,
    height: 675,
    caption: 'Aerial Views IV - acrylic, ink and candy foil on canvas paper - 16.5"x23.4"'
  },
  {
    src: image20,
    width: 960,
    height: 678,
    caption: 'Aerial Views V - acrylic, oil pen and candy foil on canvas paper - 16.5"x23.4". '
  },
  {
    src: image21,
    width: 960,
    height: 672,
    caption: 'Aerial Views VI - acrylic, oil pen and candy foil on canvas paper - 16.5"x23.4" '
  },
  {
    src: image22,
    width: 960,
    height: 676,
    caption: 'Aerial Views VII - acrylic and candy foil on canvas paper - 16.5"x23.4"'
  },
  {
    src: image23,
    width: 960,
    height: 680,
    caption: 'Aerial Views VIII - acrylic, oil pen and candy foil on canvas paper - 16.5" x 23.4"'
  },
  {
    src: image24,
    width: 960,
    height: 676,
    caption: 'Aerial Views IX - acrylic and candy foil on canvas paper - 16.5" x 23.4"'
  },
  {
    src: image25,
    width: 960,
    height: 672,
    caption: 'Aerial Views X - acrylic, oil pen and candy foil on canvas paper - 16.5" x 23.4"'
  },
  {
    src: image26,
    width: 960,
    height: 679,
    caption: 'Aerial Views XI - acrylic, oil pen and candy foil on canvas paper - 16.5" x 23.4"'
  },
  {
    src: image27,
    width: 960,
    height: 677,
    caption: 'Aerial Views XII - acrylic, ink and candy foil on canvas paper - 16.5" x 23.4"'
  },
  {
    src: image28,
    width: 960,
    height: 676,
    caption: 'Aerial Views XIII - acrylic and candy foil on canvas paper - 16.5" x 23.4"'
  },
  {
    src: image29,
    width: 960,
    height: 677,
    caption: 'Aerial Views XIV - acrylic, oil pen and candy foil on canvas paper - 16.5" x 23.4"'
  },
  {
    src: image30,
    width: 960,
    height: 678,
    caption: 'Aerial Views XV - acrylic and candy foil on canvas paper - 16.5" x 23.4"'
  },
  {
    src: image31,
    width: 960,
    height: 674,
    caption: 'Aerial Views XVI - acrylic and candy foil on canvas paper - 16.5" x 23.4"'
  },
  {
    src: image32,
    width: 960,
    height: 680,
    caption: 'Dripping - acrylic and oil pen on canvas paper - 23.4"x33"'
  },
  {
    src: image33,
    width: 810,
    height: 960,
    caption: 'Formation/Deformation - 15 pieces acrylic on canvas paper - 58.5"x49.5"'
  },
  {
    src: image34,
    width: 960,
    height: 1132,
    caption: 'Antártida Nocturna" - 15 pieces - acrylic, metallic candy paper, pigments on canvas paper - 58.5" x 49.5'
  }
]
